import React from 'react';

const IconMedium = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-github">
        <title>Medium</title>
        <g>
            <path d="M 22.085938 4.734375 L 24 2.902344 L 24 2.5 L 17.367188 2.5 L 12.636719 14.269531 L 7.257812 2.5 L 0.304688 2.5 L 0.304688 2.902344 L 2.539062 5.59375 C 2.757812 5.792969 2.871094 6.085938 2.84375 6.378906 L 2.84375 16.960938 C 2.910156 17.339844 2.789062 17.734375 2.519531 18.011719 L 0 21.0625 L 0 21.460938 L 7.144531 21.460938 L 7.144531 21.058594 L 4.625 18.011719 C 4.351562 17.730469 4.222656 17.347656 4.277344 16.960938 L 4.277344 7.804688 L 10.550781 21.464844 L 11.277344 21.464844 L 16.671875 7.804688 L 16.671875 18.6875 C 16.671875 18.972656 16.671875 19.03125 16.484375 19.222656 L 14.542969 21.097656 L 14.542969 21.5 L 23.957031 21.5 L 23.957031 21.097656 L 22.085938 19.269531 C 21.921875 19.144531 21.835938 18.9375 21.871094 18.734375 L 21.871094 5.265625 C 21.835938 5.0625 21.921875 4.855469 22.085938 4.734375 Z M 22.085938 4.734375 " />
        </g>
    </svg>
);



export default IconMedium;
