import React from 'react';

const IconThumbnail = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-github">
        <title>Image</title>
        <g>
            <path d="M 21.75 0 L 2.25 0 C 1.007812 0 0 1.007812 0 2.25 L 0 21.75 C 0 22.992188 1.007812 24 2.25 24 L 21.75 24 C 22.992188 24 24 22.992188 24 21.75 L 24 2.25 C 24 1.007812 22.992188 0 21.75 0 Z M 22.5 21.75 C 22.5 22.164062 22.164062 22.5 21.75 22.5 L 2.25 22.5 C 1.835938 22.5 1.5 22.164062 1.5 21.75 L 1.5 2.25 C 1.5 1.835938 1.835938 1.5 2.25 1.5 L 21.75 1.5 C 22.164062 1.5 22.5 1.835938 22.5 2.25 Z M 22.5 21.75 " />
            <path d="M 16.28125 12.96875 C 15.988281 12.675781 15.515625 12.675781 15.226562 12.96875 L 13.5 14.691406 L 10.28125 11.476562 C 9.988281 11.183594 9.515625 11.183594 9.226562 11.476562 L 3.976562 16.726562 C 3.683594 17.019531 3.691406 17.496094 3.984375 17.785156 C 4.121094 17.921875 4.308594 17.996094 4.5 18 L 19.5 18 C 19.914062 18.003906 20.25 17.667969 20.253906 17.253906 C 20.253906 17.054688 20.175781 16.859375 20.03125 16.71875 Z M 16.28125 12.96875 " />
            <path d="M 16.5 8.25 C 16.5 9.492188 15.492188 10.5 14.25 10.5 C 13.007812 10.5 12 9.492188 12 8.25 C 12 7.007812 13.007812 6 14.25 6 C 15.492188 6 16.5 7.007812 16.5 8.25 Z M 16.5 8.25 " />
        </g>
    </svg>
);



export default IconThumbnail;
